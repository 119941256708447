/* eslint-disable react-hooks/exhaustive-deps */
import CloseIcon from "@mui/icons-material/Close";
import ContactEmergencyIcon from "@mui/icons-material/ContactEmergency";
import InfoIcon from "@mui/icons-material/Info";

import MedicationIcon from "@mui/icons-material/Medication";
import PersonIcon from "@mui/icons-material/Person";
import SendIcon from "@mui/icons-material/Send";
import {
  Button,
  CircularProgress,
  Divider,
  FormControlLabel,
  Grid,
  InputAdornment,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { useFormik } from "formik";
import React, { useEffect, useMemo, useRef, useState } from "react";
import ReactAutocomplete from "react-autocomplete";
import { useQuery } from "react-query";
import { useDebounce } from "use-debounce";
import { FileInput } from "../../../components/FileInput/FileInput";
import { RppsAutocompleteByNameInput } from "../../../components/inputs/RppsAutocompleteByNameInput";
import Questions from "../../../components/Questions";
import {
  CreateAnswerDto,
  CreateAppointmentRequestDto,
  RppsDto,
  Site,
} from "../../../interfaces/api-interfaces";
import { AppointmentRequestService } from "../../../services/appointment-request.service";
import { BanService } from "../../../services/ban.service";
import { ExamService } from "../../../services/exam.service";
import { QuestionService } from "../../../services/question.service";
import { SiteService } from "../../../services/sites.service";
import {
  SinemPatientFormSchema,
  SinemPatientFormValues,
} from "../../../shared/schemas/sinem-apointment-form.schema";
import { isValidDate } from "../../../utils";
import AppointmentSuccess from "./AppointmentSuccess";
import DateInput from "../../../components/inputs/DateInput";

const SinemPatientForm = () => {
  const siteService = useRef(new SiteService()).current;
  const examService = useRef(new ExamService()).current;
  const questionService = useRef(new QuestionService()).current;
  const banService = useRef(new BanService()).current;
  const [files, setFiles] = useState<File[]>([]);
  const [specificDate, setSpecificDate] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const answersMap = useRef(new Map<number, CreateAnswerDto>()).current;
  const [addressSearch, setAddressSearch] = useState("");
  const [ssnEnabled, setSsnEnabled] = useState(false);
  const [selectedDepartment, setSelectedDepartment] = useState("");
  const [isSending, setIsSending] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const canUpdateSsn = useRef(true);
  const handleSubmit = async (values: SinemPatientFormValues) => {
    if (values.referringDoctor === null) {
      return;
    }

    const dto: CreateAppointmentRequestDto = {
      examId: parseInt(values.exam + ""),
      patientFirstName: values.patientFirstName,
      patientlastName: values.patientLastName,
      patientAlreadyCame: values.patientAlreadyCame,
      patientBirthDate: values.patientBirthDate.replace(/\//g, "-"),
      patientEmail: values.patientEmail,
      patientSex: values.patientSex === "male" ? "M" : "F",
      patientPhone: values.patientPhone,
      patientWeight: values.patientWeight,
      siteId: parseInt(values.site + ""),
      submittedBy: "patient",
      answers: Array.from(answersMap.values()),
      patientAddress: values.patientAddress,
      patientBirthPlace: values.patientBirthPlace,
      patientSsn: values.ssn,
      patientMobility: values.patientMobility,
      resultViaEmail: values.resultViaEmail,
      patientHeight: values.patientHeight,
      preferredDate:
        values.preferedDate === "specific" ? specificDate : values.preferedDate,
      referringDoctorEmail: "",
      referringDoctor: values.referringDoctor,
      files,
      comments: values.comments,
      foreignPatient: values.foreignPatient,
      patientOrigin: values.patientOrigin,
    };

    const appointmentRequestService = new AppointmentRequestService();
    try {
      setIsSending(true);
      if (errorMessage) {
        setErrorMessage("");
      }
      await appointmentRequestService.create(dto);
      setIsSuccess(true);
    } catch (err) {
      setErrorMessage(
        "Il y a eu un problème, merci de réessayer plus tard ou de nous contacter."
      );
    } finally {
      setIsSending(false);
    }
  };

  const formik = useFormik<SinemPatientFormValues>({
    initialValues: {
      patientBirthPlace: "",
      patientSex: "",
      ssn: "",
      patientFirstName: "",
      patientLastName: "",
      patientPhone: "",
      patientEmail: "",
      patientBirthDate: "",
      examType: "",
      preferedDate: "",
      doctorRpps: "",
      comments: "",
      resultViaEmail: true,
      patientAlreadyCame: false,
      exam: -1,
      patientHeight: 0,
      patientWeight: 0,
      site: -1,
      patientAddress: "",
      referringDoctor: null,
      patientMobility: "",
      patientOrigin: "E",
      foreignPatient: false,
    },
    validationSchema: SinemPatientFormSchema,
    onSubmit: handleSubmit,
    validateOnChange: false,
    validateOnBlur: true,
  });

  const clientId = 5;
  const { data: sites } = useQuery<Site[]>(
    "sites",
    () => siteService.find(clientId),
    {
      initialData: [],
    }
  );

  const { data: examTypes } = useQuery({
    queryKey: ["examTypes", formik.values.site],
    queryFn: ({ queryKey }) => examService.findExamTypes(queryKey[1] as number),
    initialData: [],
  });

  const { data: exams } = useQuery({
    queryKey: ["exams", formik.values.site, formik.values.examType],
    queryFn: ({ queryKey }) => {
      const siteId = isNaN(queryKey[1] as number)
        ? undefined
        : (queryKey[1] as number);
      const examTypeId = isNaN(queryKey[2] as number)
        ? undefined
        : (queryKey[2] as number);

      return examService.findExams(siteId, examTypeId);
    },
    initialData: { totalCounts: 0, datas: [] },
  });

  const { data: questions } = useQuery({
    queryKey: ["questions", formik.values.exam],
    queryFn: ({ queryKey }) => questionService.find(queryKey[1] as number),
    initialData: [],
    enabled: !!formik.values.exam,
  });
  const addressSearchTerm = useDebounce(addressSearch, 500);
  const { data: addresses } = useQuery({
    queryKey: ["addresses", addressSearchTerm[0]],
    queryFn: ({ queryKey }) => banService.search(queryKey[1]),
    initialData: [],
    enabled: !!addressSearchTerm[0],
  });

  const siteOptions = sites?.map((site) => ({
    label: site.label,
    value: site.id,
  })) as { label: string; value: string | number }[];

  const examTypeOptions = examTypes?.map((type) => ({
    label: type.label,
    value: type.id.toString(),
  })) as { label: string; value: string }[];

  const examOptions = exams?.datas?.map((ex) => ({
    label: ex.label,
    value: ex.id.toString(),
  })) as { label: string; value: string }[];
  const addressOptions = addresses?.map((address) => ({
    label: address.place,
    value: address.place,
  })) as { label: string; value: string }[];

  const handleRppsChange = (rpps: RppsDto | null) => {
    const { setFieldValue } = formik;
    setFieldValue("referringDoctor", {
      doctorName: rpps?.firstName + " " + rpps?.lastName,
      rpps: rpps?.rppsNumber,
      id: rpps?.id,
      skill: rpps?.skillLabel,
    });

    //TODO remove this
    setFieldValue("doctorRpps", rpps?.rppsNumber);
  };
  const handleFileChange = (file: File) => {
    setFiles([...files, file]);
  };
  const handleFileDelete = (file: File) => {
    setFiles(files.filter((f) => f !== file));
  };

  const handleAnswerChange = (answerDto: CreateAnswerDto) => {
    const key = answerDto.questionId;
    answersMap.set(key, answerDto);
  };

  const onSubmit = async () => {
    const errors = await formik.validateForm();
    if (formik.values.foreignPatient && !formik.values.patientBirthPlace) {
      formik.setFieldValue("patientBirthPlace", "foreign");
    }

    if (Object.values(errors).length !== 0) {
      setErrorMessage(
        "Certains champs obligatoires ne sont pas renseignés ou sont incorrects, merci de vérifier et soumettre à nouveau"
      );
    }
    await formik.submitForm();
  };

  const onBirthPlaceChange = (value: string) => {
    const { setFieldValue } = formik;
    setFieldValue("patientBirthPlace", value);
    setSelectedDepartment(value.split(",")[0]);
    setAddressSearch(value.replace(/\s/g, "-"));
  };

  const isSpecificDateVisible = () => {
    const v = formik.values.preferedDate;
    return (
      v !== "Urgence" && v !== "-15 jours" && v !== "+15 jours" && v !== ""
    );
  };

  useEffect(() => {
    answersMap.clear();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.exam]);

  useEffect(() => {
    if (!canUpdateSsn.current) {
      canUpdateSsn.current = true;
      return;
    }

    const { ssn, patientSex, patientBirthDate } = formik.values;

    const [day, month, year] = patientBirthDate.split("/").map(Number);

    if (
      selectedDepartment &&
      patientSex &&
      patientBirthDate &&
      isValidDate(day, month, year)
    ) {
      setSsnEnabled(true);
      const monthStr = month < 10 ? `0${month}` : `${month}`;
      const yearStr = year.toString().substring(2);
      let ssnStr = "";

      if (ssn) {
        if (ssn.length < 7) {
          ssnStr = `${
            patientSex === "male" ? 1 : 2
          }${yearStr}${monthStr}${selectedDepartment}${ssnStr}`;
          formik.setFieldValue("ssn", ssnStr);
          canUpdateSsn.current = false;
          return;
        } else if (ssn.length > 15) {
          ssnStr = ssn.substring(0, ssn.length - 1);
          if (ssn !== ssnStr) {
            formik.setFieldValue("ssn", ssnStr);
          }
          canUpdateSsn.current = false;
          return;
        }
        ssnStr = ssn.substring(7, ssn.length);
      }

      ssnStr = `${
        patientSex === "male" ? 1 : 2
      }${yearStr}${monthStr}${selectedDepartment}${ssnStr}`;
      if (ssnStr !== ssn) {
        formik.setFieldValue("ssn", ssnStr);
      }
    } else {
      setSsnEnabled(false);
    }
  }, [
    formik.values.patientBirthDate,
    formik.values.patientSex,
    selectedDepartment,
    formik.values,
  ]);

  useEffect(() => {
    if (formik.values.patientMobility === "FALSE") {
      formik.setFieldValue("patientMobility", "stretcher");
    }
  }, [formik.values.patientMobility]);

  const handleForeignPatient = (ev: React.ChangeEvent<any>) => {
    const value = ev.target.value === "true";
    formik.setFieldValue("foreignPatient", value);
    if (value === true) {
      setSelectedDepartment("99");
      formik.setFieldValue("patientBirthPlace", "Étranger");
    } else {
      const patientBirthPlace = formik.values.patientBirthPlace;
      if (patientBirthPlace) {
        formik.setFieldValue("patientBirthPlace", "");
        setSelectedDepartment("");
      }
    }
  };

  const handleSsnChange = (ev: React.ChangeEvent<any>) => {
    if (ev.target.value.length < 5) {
      ev.preventDefault();
    } else {
      formik.handleChange(ev);
    }
  };

  const instructions = useMemo(() => {
    const ex = exams?.datas.find(
      (ex) => ex.id === parseInt(formik.values.exam.toString())
    );

    return ex?.instructionsList.sort((i1, i2) =>
      i1.label > i2.label ? 1 : -1
    );
  }, [formik.values.exam, exams?.datas]);

  const confirmationMessage = (
    <div>
      <p>
        Merci de confirmer votre demande en cliquant sur le lien envoyé sur
        votre boite mail. Si vous ne le trouvez pas, pensez à vérifier vos
        SPAMS.
        <br />
        <br />
        Vous pouvez ajouter l'adresse email suivante à vos contacts pour vous
        assurer de la bonne réception de ces emails dans le futur:
        <br />
        <br />
        <b>prescription-web@ether.healthcare</b>
      </p>
    </div>
  );

  return isSuccess ? (
    <AppointmentSuccess message={confirmationMessage} />
  ) : (
    <Grid
      container
      spacing={1}
      justifyContent="center"
      alignItems="center"
      sx={{
        padding: "10px",
        backgroundColor: "#00509E10",
      }}
    >
      <Grid
        container
        xs={12}
        sx={{ height: "100%", padding: "20px", overflow: "auto" }}
      >
        <Grid item xs={12} sx={{ marginBottom: "5px" }}>
          <Typography
            variant="body1"
            component="h6"
            sx={{ color: "orange", fontWeight: "500" }}
          >
            <InfoIcon sx={{ color: "orange", marginRight: "2px" }} />
            Les champs marqués d'une * sont obligatoires
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Divider>
            <ContactEmergencyIcon
              sx={{ marginBottom: "-10px", color: "#00509E" }}
            />
            <Typography variant="h6" component="h6" sx={{ color: "#00509E" }}>
              Docteur
            </Typography>
          </Divider>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h6" component="h6">
            Médecin qui demande l'examen (Prénom puis Nom)*
          </Typography>
          <Grid item xs={12}>
            <Typography style={{ color: "grey", fontSize: "12px" }}>
              Merci de ne sélectionner qu'un seul résultat (vous pouvez affiner
              la recherche avec le № départment).
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <RppsAutocompleteByNameInput onChange={handleRppsChange} />
            <Typography className="MuiFormHelperText-root Mui-error MuiFormHelperText-sizeMedium MuiFormHelperText-contained css-1wc848c-MuiFormHelperText-root">
              {formik.errors.doctorRpps}
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Divider>
            <PersonIcon sx={{ marginBottom: "-10px", color: "#00509E" }} />
            <Typography variant="h6" component="h6" sx={{ color: "#00509E" }}>
              Patient
            </Typography>
          </Divider>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h6" component="h6">
            Civilité*
          </Typography>
          <RadioGroup
            aria-labelledby="genderSelectionRadioButtons"
            name="patientSex"
            value={formik.values.patientSex}
            onChange={formik.handleChange}
            row
          >
            <FormControlLabel
              value="female"
              control={<Radio />}
              label="Mme/Mlle"
            />
            <FormControlLabel value="male" control={<Radio />} label="Mr" />
          </RadioGroup>
          <Typography className="MuiFormHelperText-root Mui-error MuiFormHelperText-sizeMedium MuiFormHelperText-contained css-1wc848c-MuiFormHelperText-root">
            {formik.errors.patientSex}
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Typography variant="h6" component="h6">
            Prénom*
          </Typography>
          <TextField
            name="patientFirstName"
            label="Prénom du patient"
            variant="outlined"
            fullWidth
            value={formik.values.patientFirstName}
            onChange={formik.handleChange}
            helperText={formik.errors.patientFirstName || ""}
            error={!!formik.errors.patientFirstName}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h6" component="h6">
            Nom de famille*
          </Typography>
          <TextField
            name="patientLastName"
            label="Nom de famille du patient"
            variant="outlined"
            fullWidth
            value={formik.values.patientLastName}
            onChange={formik.handleChange}
            helperText={formik.errors.patientLastName || ""}
            error={!!formik.errors.patientLastName}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h6" component="h6">
            Téléphone*
          </Typography>
          <TextField
            name="patientPhone"
            label="Mobile uniquement, un code de verrification vous sera envoyé pour valider votre
						demande"
            variant="outlined"
            fullWidth
            value={formik.values.patientPhone}
            onChange={formik.handleChange}
            helperText={formik.errors.patientPhone || ""}
            error={!!formik.errors.patientPhone}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h6" component="h6">
            Adresse
          </Typography>
          <TextField
            name="patientAddress"
            label="Adresse"
            variant="outlined"
            value={formik.values.patientAddress}
            onChange={formik.handleChange}
            fullWidth
            helperText={formik.errors.patientAddress || ""}
            error={!!formik.errors.patientAddress}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h6" component="h6">
            Email*
          </Typography>
          <TextField
            name="patientEmail"
            label="Adresse email valide"
            variant="outlined"
            fullWidth
            value={formik.values.patientEmail}
            onChange={formik.handleChange}
            helperText={formik.errors.patientEmail || ""}
            error={!!formik.errors.patientEmail}
          />
        </Grid>

        <Grid item xs={12}>
          <Typography variant="h6" component="h6">
            Date de naissance*
          </Typography>
          <DateInput
            value={formik.values.patientBirthDate}
            onChange={(value) =>
              formik.setFieldValue("patientBirthDate", value)
            }
            error={!!formik.errors.patientBirthDate}
            helperText={formik.errors.patientBirthDate}
          />
        </Grid>

        <Grid item xs={12}>
          <Typography variant="h6" component="h6">
            Lieu de naissance*
          </Typography>
          <Grid item xs={12}>
            <span style={{ fontFamily: "Roboto" }}>
              {" "}
              Patient(e) né(e) à l'etranger?{" "}
            </span>
            <RadioGroup
              aria-labelledby="foreign patient"
              name="foreignPatient"
              value={formik.values.foreignPatient}
              onChange={handleForeignPatient}
              row
            >
              <FormControlLabel value={false} control={<Radio />} label="Non" />
              <FormControlLabel value={true} control={<Radio />} label="Oui" />
            </RadioGroup>
          </Grid>
          {!formik.values.foreignPatient && (
            <ReactAutocomplete
              menuStyle={{
                borderRadius: "3px",
                boxShadow: "0 2px 12px rgba(0, 0, 0, 0.1)",
                background: "#fff",
                padding: "10px",
                fontSize: "1em",
                fontFamily: "Roboto",
                position: "fixed",
                overflow: "auto",
                maxHeight: "50%", // TODO: don't cheat, let it flow to the bottom
                zIndex: 100,
              }}
              inputProps={{
                style: {
                  width: "100%",
                  height: "50px",
                  fontFamily: "Roboto",
                  fontSize: "1em",
                },
                placeholder: "Nom de votre ville de naissance",
              }}
              wrapperStyle={{ width: "50%" }}
              getItemValue={(item) => item.label}
              items={addressOptions}
              renderItem={(item: any, isHighlighted: any) => (
                <div
                  style={{
                    background: isHighlighted ? "lightblue" : "white",
                    cursor: "pointer",
                    padding: "5px",
                  }}
                >
                  {item.label}
                </div>
              )}
              value={addressSearch}
              onChange={(e) =>
                setAddressSearch(e.target.value.replace(/\s/g, "-"))
              }
              onSelect={onBirthPlaceChange}
            />
          )}
        </Grid>

        <Grid item xs={12}>
          <Typography variant="h6" component="h6">
            N° Sécurité Sociale*
          </Typography>
          <TextField
            name="ssn"
            label="SSN"
            disabled={!ssnEnabled}
            variant="outlined"
            value={formik.values.ssn}
            onChange={handleSsnChange}
            fullWidth
            inputProps={{
              maxlength: 15,
            }}
            helperText={formik.errors.ssn || ""}
            error={!!formik.errors.ssn}
          />
        </Grid>

        <Grid item xs={12}>
          <Typography variant="h6" component="h6">
            Souhaitez-vous recevoir vos résultat par email / en ligne?
          </Typography>
          <RadioGroup
            aria-labelledby="resultViaEmailSelectionRadioButtons"
            name="resultViaEmail"
            value={formik.values.resultViaEmail}
            onChange={formik.handleChange}
            row
          >
            <FormControlLabel value={true} control={<Radio />} label="Oui" />
            <FormControlLabel value={false} control={<Radio />} label="Non" />
          </RadioGroup>
        </Grid>

        <Grid item xs={12} sx={{ marginTop: "5px" }}>
          <Divider>
            <MedicationIcon sx={{ marginBottom: "-10px", color: "#00509E" }} />
            <Typography variant="h6" component="h6" sx={{ color: "#00509E" }}>
              Examen
            </Typography>
          </Divider>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h6" component="h6">
            Choisissez votre centre d'imagerie*
          </Typography>
          <Select
            name="site"
            value={formik.values.site}
            fullWidth
            onChange={formik.handleChange}
            error={!!formik.errors.site}
          >
            {siteOptions.map((site) => (
              <MenuItem key={site.value} value={site.value}>
                {site.label}
              </MenuItem>
            ))}
          </Select>
          <Typography style={{ color: "red" }}>{formik.errors.site}</Typography>
        </Grid>
        {formik.values.site && (
          <>
            <Grid item xs={12}>
              <Typography variant="h6" component="h6">
                Êtes-vous déjà venu dans ce centre d'imagerie?
              </Typography>

              <RadioGroup
                aria-labelledby="patientAlreadyCameSelectionRadioButtons"
                name="patientAlreadyCame"
                value={formik.values.patientAlreadyCame}
                onChange={formik.handleChange}
                row
              >
                <FormControlLabel
                  value={true}
                  control={<Radio />}
                  label="Oui"
                />
                <FormControlLabel
                  value={false}
                  control={<Radio />}
                  label="Non"
                />
              </RadioGroup>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h6" component="h6">
                Type d'examen*
              </Typography>
              <Select
                name="examType"
                value={formik.values.examType}
                fullWidth
                onChange={formik.handleChange}
                error={!!formik.errors.examType}
              >
                {examTypeOptions.map((examType) => (
                  <MenuItem key={examType.value} value={examType.value}>
                    {examType.label}
                  </MenuItem>
                ))}
              </Select>
              <Typography className="MuiFormHelperText-root Mui-error MuiFormHelperText-sizeMedium MuiFormHelperText-contained css-1wc848c-MuiFormHelperText-root">
                {formik.errors.examType}
              </Typography>
            </Grid>
          </>
        )}
        {formik.values.examType && (
          <Grid item xs={12}>
            <Typography variant="h6" component="h6">
              Examen*
            </Typography>
            <Select
              name="exam"
              value={formik.values.exam}
              fullWidth
              onChange={formik.handleChange}
              error={!!formik.errors.exam}
            >
              {examOptions.map((exam) => (
                <MenuItem key={exam.value} value={exam.value}>
                  {exam.label}
                </MenuItem>
              ))}
            </Select>
            <Typography className="MuiFormHelperText-root Mui-error MuiFormHelperText-sizeMedium MuiFormHelperText-contained css-1wc848c-MuiFormHelperText-root">
              {formik.errors.exam}
            </Typography>
          </Grid>
        )}
        {formik.values.exam && (
          <Grid item xs={12}>
            <Typography variant="h6" component="h6">
              Période de rendez-vous souhaitée*
            </Typography>

            <Select
              name="preferedDate"
              value={formik.values.preferedDate}
              fullWidth
              onChange={formik.handleChange}
              error={!!formik.errors.preferedDate}
            >
              <MenuItem value={"Urgence"}>Urgence</MenuItem>
              <MenuItem value={"-15 jours"}>-15 jours</MenuItem>
              <MenuItem value={"+15 jours"}>+15 jours</MenuItem>
              <MenuItem value={"specific"}>
                Spécifique{" "}
                <span
                  style={{
                    color: "gray",
                    fontSize: "0.8rem",
                    marginLeft: "5px",
                  }}
                >
                  (précisez)
                </span>
              </MenuItem>
            </Select>
            <Typography className="MuiFormHelperText-root Mui-error MuiFormHelperText-sizeMedium MuiFormHelperText-contained css-1wc848c-MuiFormHelperText-root">
              {formik.errors.preferedDate}
            </Typography>
          </Grid>
        )}
        {isSpecificDateVisible() && (
          <Grid item xs={12} sx={{ marginTop: "10px" }}>
            <TextField
              name="preferedDate"
              label="Contraintes / Préférences"
              placeholder="ex: Seulement les lundis"
              variant="outlined"
              value={specificDate}
              onChange={(ev) => setSpecificDate(ev.target.value)}
              multiline
              rows={4}
              fullWidth
              error={!!formik.errors.preferedDate}
              helperText={formik.errors.preferedDate}
            />
          </Grid>
        )}
        {formik.values.exam && (
          <>
            <Grid item xs={12}>
              <Typography variant="h6" component="h6">
                Taille{" "}
                <span style={{ color: "gray", fontSize: "0.8em" }}>
                  (en cm)
                </span>
              </Typography>
              <TextField
                type="number"
                name="patientHeight"
                label="Taille"
                variant="outlined"
                fullWidth
                value={
                  formik.values.patientHeight
                    ? parseInt(formik.values.patientHeight.toString())
                    : ""
                }
                onChange={formik.handleChange}
                error={!!formik.errors.patientHeight}
                helperText={formik.errors.patientHeight}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">cm</InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h6" component="h6">
                Poids{" "}
                <span style={{ color: "gray", fontSize: "0.8em" }}>
                  (en kg)
                </span>
              </Typography>
              <TextField
                type="number"
                name="patientWeight"
                label="Poids"
                variant="outlined"
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">kg</InputAdornment>
                  ),
                }}
                value={
                  formik.values.patientWeight
                    ? parseInt(formik.values.patientWeight.toString())
                    : ""
                }
                onChange={formik.handleChange}
                error={!!formik.errors.patientWeight}
                helperText={formik.errors.patientWeight}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h6" component="h6">
                Patient valide?*
              </Typography>
              <Select
                name="patientMobility"
                value={formik.values.patientMobility}
                fullWidth
                onChange={formik.handleChange}
                error={!!formik.errors.patientMobility}
              >
                <MenuItem value="Oui">Oui</MenuItem>
                <MenuItem value="Fauteuil roulant">Fauteuil roulant</MenuItem>
                <MenuItem value="Brancard">Brancard</MenuItem>
              </Select>
              <Typography className="MuiFormHelperText-root Mui-error MuiFormHelperText-sizeMedium MuiFormHelperText-contained css-1wc848c-MuiFormHelperText-root">
                {formik.errors.patientMobility}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              {questions?.map((_question) => (
                <Questions
                  question={_question}
                  onAnswerChange={handleAnswerChange}
                />
              ))}
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h6" component="h6">
                Informations Médicales
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <TextField
                name="comments"
                label="Informations / Motifs"
                variant="outlined"
                multiline
                minRows={4}
                fullWidth
                value={formik.values.comments}
                onChange={formik.handleChange}
                error={!!formik.errors.comments}
                helperText={!!formik.errors.comments}
              />
            </Grid>
            <Grid item xs={12} sx={{ marginTop: "5px" }}>
              <Divider>
                <Typography
                  variant="h6"
                  component="h6"
                  sx={{ color: "#00509E" }}
                >
                  Documents
                </Typography>
              </Divider>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h6" component="h6">
                Joindre une ordonnance / courrier / prise de sang...
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <FileInput
                files={files}
                onChange={handleFileChange}
                onDelete={handleFileDelete}
              />
            </Grid>
            <Grid item xs={12}></Grid>

            <Grid item xs={12} sx={{ marginTop: "5px" }}>
              <Divider>
                <Typography
                  variant="h6"
                  component="h6"
                  sx={{ color: "#00509E" }}
                >
                  Instructions à suivre
                </Typography>
              </Divider>
            </Grid>
            <Grid container sx={{ padding: "20px" }}>
              <Grid
                item
                xs={10}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
                {instructions?.map((_instruction, index) => (
                  <Typography
                    key={index}
                    variant="h6"
                    component="p"
                    sx={{
                      ":first-letter": {
                        color: "red",
                        fontWeight: "bold",
                        fontSize: "1.2em",
                      },
                    }}
                  >
                    {_instruction.label}
                  </Typography>
                ))}
              </Grid>
            </Grid>
            {errorMessage && (
              <Grid
                item
                xs={12}
                sx={{
                  marginTop: "15px",
                  backgroundColor: "#fce5e8",
                  padding: "8px",
                  position: "relative",
                }}
              >
                <CloseIcon
                  style={{
                    float: "right",
                    right: 4,
                    color: "red",
                    fontSize: "18px",
                    cursor: "pointer",
                    fontWeight: "bold",
                  }}
                  onClick={() => setErrorMessage("")}
                />
                <Typography
                  variant="body1"
                  style={{ color: "red" }}
                  fontSize={14}
                >
                  {errorMessage}
                </Typography>
              </Grid>
            )}
            <Grid item xs={12} sx={{ marginTop: "15px" }}>
              <Button
                onClick={onSubmit}
                variant="contained"
                endIcon={
                  isSending ? (
                    <CircularProgress size={20} style={{ color: "darkgray" }} />
                  ) : (
                    <SendIcon />
                  )
                }
                fullWidth
                disabled={isSending}
              >
                {isSending ? "Patientez" : "Confirmer"}
              </Button>
            </Grid>
          </>
        )}
      </Grid>
    </Grid>
  );
};

export default SinemPatientForm;
