import * as yup from "yup";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
dayjs.extend(customParseFormat);

const phoneRegExp =
  /^(?:(?:\+|00)33[\s.-]{0,3}(?:\(0\)[\s.-]{0,3})?|0)[1-9](?:(?:[\s.-]?\d{2}){4}|\d{2}(?:[\s.-]?\d{3}){2})/;

export const SinemPatientFormSchema = yup.object({
  patientSex: yup.string().required("Merci de renseigner une civilité"),
  patientFirstName: yup.string().required("Merci de renseigner un prénom"),
  patientLastName: yup
    .string()
    .required("Merci de renseigner un nom de famille"),
  patientPhone: yup
    .string()
    .matches(phoneRegExp, "Numéro non valide")
    .required("Merci de renseigner un numéro de téléphone valide"),
  patientEmail: yup
    .string()
    .email("Merci d'utiliser une adresse email valide")
    .required("l'adresse email est obligatoire"),
  patientAddress: yup.string().optional(),
  ssn: yup
    .string()
    .length(15, "Le numéro de sécurité sociale doit comporter 15 caractères")
    .required("Merci de renseigner un numéro votre N° de sécurité sociale"),
  patientBirthDate: yup
    .string()
    .required("Merci de renseigner une date de naissance")
    .test(
      "is-valid-date",
      "Date de naissance invalide (format attendu : JJ/MM/AAAA)",
      (value) => {
        return dayjs(value, "DD/MM/YYYY", true).isValid();
      }
    ),
  patientBirthPlace: yup
    .string()
    .required("Merci de renseigner lieu de naissance"),
  patientHeight: yup
    .number()
    .min(20, "Merci de renseigner une taille valide en cm")
    .max(350, "Merci de renseigner une taille valide en cm")
    .optional(),
  patientWeight: yup
    .number()
    .min(5, "Merci de renseigner un poids valide")
    .max(350, "Merci de renseigner un poids valide")
    .optional(),
  patientMobility: yup
    .string()
    .required("Merci de renseigner cette information"),
  patientOrigin: yup.string().required("Merci de renseigner cette information"),
  site: yup.number().required("Merci de séléctioner un site"),
  examType: yup.string().required("Merci de renseigner un type d'examen"),
  exam: yup
    .number()
    .required("Merci de renseigner un examen")
    .min(1, "Merci de renseigner un examen"),
  preferedDate: yup
    .string()
    .required("Merci de renseigner une préférence de date"),
  doctorRpps: yup.string().required("Merci de renseigner un docteur"),
  comments: yup.string(),
  patientAlreadyCame: yup.boolean().required(),
  resultViaEmail: yup.boolean().required(),
  foreignPatient: yup.boolean().optional(),
});

export type SinemPatientFormValues = yup.InferType<
  typeof SinemPatientFormSchema
> & {
  referringDoctor: {
    doctorName: string;
    rpps: string;
    id: number;
    skill: string;
  } | null;
};

export const SinemDoctorFormSchema = yup.object({
  patientSex: yup.string().required("Merci de renseigner une civilité"),
  patientFirstName: yup.string().required("Merci de renseigner un prénom"),
  patientLastName: yup
    .string()
    .required("Merci de renseigner un nom de famille"),
  patientPhone: yup
    .string()
    .matches(phoneRegExp, "Numéro non valide")
    .optional(),
  patientAddress: yup.string().optional(),
  patientBirthDate: yup
    .string()
    .required("Merci de renseigner une date de naissance")
    .test(
      "is-valid-date",
      "Date de naissance invalide (format attendu : JJ/MM/AAAA)",
      (value) => {
        return dayjs(value, "DD/MM/YYYY", true).isValid();
      }
    ),
  patientHeight: yup
    .number()
    .min(20, "Merci de renseigner une taille valide en cm")
    .max(300, "Merci de renseigner une taille valide en cm")
    .optional(),
  patientWeight: yup
    .number()
    .min(5, "Merci de renseigner un poids valide")
    .max(350, "Merci de renseigner un poids valide")
    .optional(),
  patientMobility: yup
    .string()
    .required("Merci de renseigner cette information"),
  patientOrigin: yup.string().required("Merci de renseigner cette information"),
  site: yup.number().required("Merci de séléctioner un site"),
  examType: yup.string().required("Merci de renseigner un type d'examen"),
  exam: yup
    .number()
    .required("Merci de renseigner un examen")
    .min(1, "Merci de renseigner un examen"),
  preferedDate: yup
    .string()
    .required("Merci de renseigner une préférence de date"),
  doctorRpps: yup.string().required("Merci de renseigner un docteur"),
  doctorEmail: yup.string().email("Merci de saisir une adresse email valide"),
  doctorPhone: yup
    .string()
    .required("Merci de renseigner numéro de téléphone valide"),
  comments: yup.string(),
  resultViaEmail: yup.boolean(),
});

export type SinemDoctorFormValues = yup.InferType<
  typeof SinemDoctorFormSchema
> & {
  referringDoctor: {
    doctorName: string;
    rpps: string;
    id: number;
    skill: string;
  } | null;
};
